.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-body {
  background-color: #ffffcc;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  font-size: calc(2px + 2vmin);
  color: black;
  width: 100%;
}
.App-controls {
  margin-top: 25px;
  display: flex;
  flex-direction: row;
}

.App-left-controls {
  flex-basis: 10%;
  display: flex;
  flex-direction: column;
  border: 2px solid #000;
  align-items: center;
}
.App-map {
  border: 2px solid #000;
  display: flex;
  flex-basis: 80%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.App-right-controls {
  border: 2px solid #000;
  flex-basis: 10%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tiles-selector {
  width: 100%;
}

.tiles-gallery {
  display: flex !important;
  padding: 5px;
  flex-wrap: wrap;
}

.brush-selector {
  display: flex;
}

.aditional-brushes {
  justify-content: center;
  align-items: center;
  display: flex;
}

.grid {
  z-index: 9999;
  pointer-events: none;
}

.toggle-grid {
  margin: 8px;
  display: inline-block;
}

input[type="checkbox"] {
  vertical-align: middle;
  position: relative;
  bottom: 1px;
  margin: 8px;
}

.save-load-map-buttons {
  display: flex;
  flex-direction: column;
}

.tiles-panel {
  display: flex;
  flex-direction: column;
}

label.upload-map input[type="file"] {
  position: absolute;
  top: -1000px;
}

.upload-map {
  border: 2px solid #aaa;
  border-radius: 4px;
  padding: 2px 5px;
  margin: 2px;
  background: #ddd;
  display: inline-block;
}

.upload-map:hover {
  background: #ccc;
}
.upload-map:active {
  background: #ccf;
}
.upload-map :invalid + span {
  color: #a44;
}
.upload-map :valid + span {
  color: #4a4;
}

label.upload-tiles input[type="file"] {
  position: absolute;
  top: -1000px;
}

.upload-tiles {
  border: 2px solid #aaa;
  border-radius: 4px;
  padding: 2px 5px;
  margin: 2px;
  background: #ddd;
  display: inline-block;
}

.upload-tiles:hover {
  background: #ccc;
}
.upload-tiles:active {
  background: #ccf;
}
.upload-tiles :invalid + span {
  color: #a44;
}
.upload-tiles :valid + span {
  color: #4a4;
}

.tiles-gallery .drag-tile {
  padding: 2px;
}

.App-link {
  color: dark-blue;
}

.map-canvas-container {
  padding: 10px;
  position: relative;
  width: 100%;
  height: 65vh;
  overflow: auto;
  margin-left: auto;
  margin-right: auto;
  display: block;
  min-width: 0;
  min-height: 0;
}

.map-canvas {
  margin: 0;
  position: absolute;
  border: 4px solid;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.awesome-map {
  line-height: 0;
  border-style: solid;
  border-color: black;
  position: relative;
  z-index: 1;
}

.btn-tile-selection {
  background-size: cover;
}

.map-layer {
  line-height: 0;
  opacity: 20;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: block;
  z-index: 2;
  background: transparent;
}

.brush-pointer:hover {
  cursor: url("img/brush.png") 0 32, auto;
}
.eraser-pointer:hover {
  cursor: url("img/eraser.png") 16 24, auto;
}
.bucket-pointer:hover {
  cursor: url("img/bucket.png") 0 0, auto;
}
.clear-pointer:hover {
  cursor: url("img/clear.png") 0 0, auto;
}

.layer-selector {
  display: flex;
  justify-content: flex-start;
}

.layer-name {
  margin: auto;
}

.layer-actions {
  display: flex;
  justify-content: flex-end;
  margin-left: 5px;
}

.delete-layer {
  width: 10px;
  margin: auto;
  margin-left: 5px;
}

.edit-layer {
  width: 15px;
  margin: auto;
  margin-left: 5px;
}

.save-layer {
  width: 15px;
  margin: auto;
  margin-left: 5px;
}
